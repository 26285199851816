import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledIllustration {
  size: any;
  align: any;
  marginTop: any;
}

const StyledIllustration = styled.div<IStyledIllustration>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: ${({ theme }) => theme.rhythmSizing(24)}rem;
  height: ${({ size, theme }) =>
    size === `large` ? theme.rhythmSizing(36) : theme.rhythmSizing(24)}rem;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop || 0};
  ${({ theme, align }) => theme.breakpoints.desktop`
    justify-content: ${align};
  `}
  .illustration__image {
    object-fit: contain;
    height: 100%;
    max-width: ${rem(`240px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      max-width: ${rem(`264px`)};
    `}
    ${({ theme }) => theme.breakpoints.tablet`
      max-width: ${rem(`312px`)};
    `}
    ${({ theme }) => theme.breakpoints.desktop`
      max-width: ${rem(`486px`)};
    `}
    object-position: 50% 50%;
    img {
      object-fit: contain !important;
    }
  }
`;

interface IIllustration {
  imageSrc: any;
  className?: string;
  alt?: string;
  align?: 'flex-start' | 'center' | 'flex-end';
  marginTop?: string;
  disableTopMargin?: boolean;
  size?: string;
  style?: any;
}

export const Illustration = ({
  imageSrc,
  className,
  alt,
  align = `flex-end`,
  disableTopMargin = false,
  marginTop,
  size,
  style,
}: IIllustration) => (
  <StyledIllustration
    data-sal="zoom-out"
    data-sal-delay="150"
    data-sal-easing="ease-in"
    style={style}
    size={size}
    marginTop={disableTopMargin ? 0 : marginTop}
    align={align}
    className={className}
  >
    <GatsbyImage image={imageSrc} alt={alt} className="illustration__image" />
  </StyledIllustration>
);
