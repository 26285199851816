import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledTextarea {
  restrictWidth: any;
}

const minHeight = 12;
const maxHeight = 24;

export const StyledTextarea = styled.label<IStyledTextarea>`
  display: flex;
  max-width: ${({ restrictWidth }) => restrictWidth};
  width: 100%;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
  padding: 1px;
  min-height: ${({ theme }) => theme.rhythmSizing(minHeight)}rem;
  max-height: ${({ theme }) => theme.rhythmSizing(maxHeight)}rem;
  border-radius: ${rem(`8px`)};
  background: linear-gradient(
    -90deg,
    ${({ theme }) => theme.colors.pink_2},
    ${({ theme }) => theme.colors.pink_2}
  );
  background-size: 100% 100%;
  :focus-within {
    animation: Gradient 5s ease normal;
  }
  overflow: hidden;
  textarea {
    flex: 1;
    resize: vertical;
    overflow: auto;
    appearance: none;
    padding: 4px 8px 0;
    min-height: calc(${({ theme }) => theme.rhythmSizing(minHeight)}rem - 2px);
    max-height: calc(${({ theme }) => theme.rhythmSizing(maxHeight)}rem - 2px);
    border: 1px solid transparent;
    border-radius: inherit;
    outline-style: none;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.white_1_60};
    background-color: ${({ theme }) => theme.colors.blue_1};
    cursor: text;
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray_3_30};
    }
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
