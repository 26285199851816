import React, { useContext, useRef, useState } from 'react';
import fetch from 'isomorphic-fetch';
import { useStaticQuery, graphql } from 'gatsby';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import {
  faUser,
  faEnvelope,
  faLampDesk,
} from '@fortawesome/pro-light-svg-icons';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { rem } from 'polished';
import { useStateValue } from '../../state';
import { ContactForm } from '../ContactForm';
import { Input } from '../Input/index';
import { Dropdown } from '../Dropdown/index';
import { Text } from '../Text';
import { Textarea } from '../Textarea/index';
import { Button } from '../Button/index';
import { Illustration } from '../Illustration';

const StyledStayInTouch = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .content {
    flex: 1 0 100%;
    min-width: 100%;
  }
  .title3.title3 {
    margin-bottom: ${rem(`24px`)};
  }
  .form__item:not(.button) {
    margin-bottom: ${rem(`12px`)};
  }
  .image {
    opacity: 1;
  }
`;

const list = [
  { value: `support`, label: `Support` },
  { value: `info`, label: `Info` },
  { value: `sales`, label: `Sales` },
  { value: `recruitment`, label: `Recruitment` },
];

interface IStayInTouch {
  className?: string;
  id: string;
  children?: any;
}

export const StayInTouch = ({ className, id, children }: IStayInTouch) => {
  const [{ formsState }, dispatch] = useStateValue();
  const { submitted, isError } = formsState;
  const breakpoints = useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isUnderTablet = [`watch`, `phone`].some((item) => item === breakpoint);
  const nameFieldRef = useRef({ value: `` });
  const emailFieldRef = useRef({ value: `` });
  const selectFieldRef = useRef({ value: `` });
  const bodyFieldRef = useRef({ value: `` });
  const [loading, setLoading] = useState(false);
  const { stayInTouchSuccessMobileImage, stayInTouchSuccessDesktopImage } =
    useStaticQuery(graphql`
      query StayInTouchSuccessImages {
        stayInTouchSuccessMobileImage: contentfulAsset(
          contentful_id: { eq: "18PCPTAzlOiHiGNFl8gOX1" }
        ) {
          gatsbyImageData(
            width: 200
            height: 150
            quality: 85
            placeholder: BLURRED
          )
        }
        stayInTouchSuccessDesktopImage: contentfulAsset(
          contentful_id: { eq: "18PCPTAzlOiHiGNFl8gOX1" }
        ) {
          gatsbyImageData(
            width: 372
            height: 279
            quality: 85
            placeholder: BLURRED
          )
        }
      }
    `);

  const mobileImage = getImage(stayInTouchSuccessMobileImage);
  const desktopImage = getImage(stayInTouchSuccessDesktopImage);

  const images = withArtDirection(desktopImage, [
    {
      image: mobileImage,
      media: `(max-width: 320px)`,
    },
  ]);

  const responseMsg = `Thank you for submiting a request, we will get back to you shortly`;
  const errorMsg = `An error occured while sumitting the form, please check fields and retry`;

  const handleError = () => {
    dispatch({
      type: `stayInTouchFormResetError`,
      newErrorState: { isError: false },
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      handleError();
      setLoading(true);
      const submitResponse = await fetch(
        `https://contacts.domusnetwork.io/new-contact`,
        {
          method: `POST`,
          headers: { 'Content-Type': `application/json` },
          body: JSON.stringify({
            name: nameFieldRef.current.value,
            email: emailFieldRef.current.value,
            subject: selectFieldRef.current.value,
            content: bodyFieldRef.current.value,
          }),
        },
      );
      if (submitResponse.ok && submitResponse.status === 200) {
        return dispatch({
          type: `stayInTouchFormSubmitted`,
          newSubmittedState: { submitted: true },
        });
      }
      throw new Error(
        `form wasn't submitted, response with: ${submitResponse.status}`,
      );
    } catch (error) {
      dispatch({
        type: `stayInTouchFormError`,
        newSubmittedState: { submitted: false },
        newErrorState: { isError: true },
      });
      return error;
    }
  };
  return (
    <StyledStayInTouch className={className}>
      {children}
      {submitted && !isError && (
        <>
          <Text type="body1" tag="p" color="yellow_1">
            {responseMsg}
          </Text>
          <Illustration
            align="center"
            marginTop="72px"
            imageSrc={images}
            className="image"
          />
        </>
      )}
      {!submitted && (
        <ContactForm
          onSubmit={handleSubmit}
          isError={isError}
          id={id}
          restrictWidth={isUnderTablet ? `372px` : `500px`}
          className="form"
        >
          {isError && (
            <Text type="body1" tag="p" color="red_1">
              {errorMsg}
            </Text>
          )}
          <Input
            refTo={nameFieldRef}
            className="form__item input"
            name="contact-name"
            type="text"
            icon={faUser}
            placeholder="type your full name here..."
            required
            minlength={3}
            maxlength={25}
            disabled={loading}
          />
          <Input
            refTo={emailFieldRef}
            className="form__item input"
            type="email"
            name="contact-email"
            icon={faEnvelope}
            placeholder="type your email here..."
            required
            minlength={5}
            maxlength={50}
            disabled={loading}
          />
          <Dropdown
            refTo={selectFieldRef}
            icon={faLampDesk}
            className="form__item dropdown"
            list={list}
            name="contact-subject"
            placeholder="Please select from dropdown"
            disabled={loading}
          />
          <Textarea
            refTo={bodyFieldRef}
            className="form__item textarea"
            name="contact-body"
            placeholder="Write us something..."
            required
            minlength={10}
            maxlength={500}
            disabled={loading}
          />
          <Button
            size="small"
            className="form__item button"
            disabled={loading}
            loading={loading}
          >
            Submit
          </Button>
        </ContactForm>
      )}
    </StyledStayInTouch>
  );
};
