import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StyledContactForm } from './styles';

interface IContactForm {
  className?: string;
  restrictWidth?: string;
  children: any;
  isError?: string;
  id: string;
  onSubmit?: (e?: object) => void;
}

const labelId = uuidv4();

export const ContactForm = ({
  className,
  children,
  restrictWidth = `100%`,
  onSubmit,
  isError,
  id,
}: IContactForm) => (
  <StyledContactForm
    isError={isError}
    onSubmit={onSubmit}
    method="post"
    id={id}
    name={id}
    className={className}
    restrictWidth={restrictWidth}
  >
    {children}
    <label htmlFor={labelId} style={{ visibility: `hidden` }}>
      Don’t fill this out if you&apos;re human:
      <input type="text" id={labelId} placeholder="bot" name="bot-field" />
    </label>
  </StyledContactForm>
);
