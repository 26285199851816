import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StyledTextarea } from './styles';

export interface ITextarea {
  changeFn?(e: React.ChangeEvent<HTMLTextAreaElement>): void;
  placeholder: string;
  restrictWidth?: string;
  className?: string;
  refTo?: any;
  name?: string;
  required?: boolean;
  minlength?: number;
  maxlength?: number;
  disabled?: boolean;
}

export const Textarea = ({
  changeFn,
  placeholder,
  restrictWidth = `100%`,
  className,
  refTo,
  name,
  required,
  minlength,
  maxlength,
  disabled = false,
}: ITextarea) => {
  const uuidNum = uuidv4();
  return (
    <StyledTextarea
      htmlFor={`${name}_${uuidNum}`}
      aria-label={name}
      restrictWidth={restrictWidth}
      className={className}
    >
      <textarea
        id={`${name}_${uuidNum}`}
        name={name}
        ref={refTo}
        onChange={changeFn}
        placeholder={placeholder}
        required={!!required}
        minLength={minlength || 0}
        maxLength={maxlength || 200}
        disabled={disabled}
      />
    </StyledTextarea>
  );
};
