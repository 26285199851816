import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { rem } from 'polished';
import { CallToAction } from '@/components/CallToAction';
import { MdContent } from '@/components/MdContent';
import { Social } from '@/components/Social';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { StayInTouch } from '../../components/StayInTouch';
import { Seo } from '../../components/SEO';

interface IContactPageTemplate {
  content?: any;
}

interface IContactPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPage: any;
  };
  location: {};
}

const StyledHeader = styled.div`
  margin-bottom: ${rem(`96px`)};
`;

const ContactPageTemplate = ({ content }: IContactPageTemplate) => {
  const { title, subtitle, body, ctaTitle, ctaHookId, ctaIcon, ctaBody } =
    content;
  return (
    <>
      <Content restrictWidth disableSubtitlePadding setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="subtitle1" tag="h3" color="white_1_45">
          {subtitle}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={body.body} />
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="90%" className="last">
        <StayInTouch id="contactPageContactForm" />
      </Content>

      <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      />
      <Social />
    </>
  );
};

const ContactPage = ({ data, location }: IContactPage) => {
  const page = get(data, `contentfulPage`);
  const {
    title,
    subtitle,
    body,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <Layout
      location={location}
      coverImage={hero}
      topSvg={svgTopPath.svg.content}
      bottomSvg={svgBottomPath.svg.content}
      variant={3}
      desktopHeading={
        <Content>
          <StyledHeader>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
          </StyledHeader>

          <Content restrictWidth setMaxWidth="90%">
            <Text type="subtitle1" tag="h3" color="white_1_45">
              {subtitle}
            </Text>
          </Content>

          <Content restrictWidth setMaxWidth="90%">
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body.body} />
            </Text>
          </Content>

          <Content restrictWidth setMaxWidth="90%" noMargin>
            <StayInTouch id="contactPageContactForm" />
          </Content>
        </Content>
      }
      desktopCta={
        <CallToAction
          hookId={ctaHookId}
          icon={ctaIcon}
          title={ctaTitle}
          body={ctaBody.ctaBody}
          maxWidth="100%"
        />
      }
    >
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDesc={metaDescription}
        cover={hero}
        slug={slug}
        templateKey={templateKey}
      />
      <Main background="transparent" disableVertPadding={false}>
        <ContactPageTemplate
          content={{
            title,
            subtitle,
            body,
            ctaTitle,
            ctaHookId,
            ctaIcon,
            ctaBody,
          }}
        />
      </Main>
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      body {
        body
      }
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
  }
`;
