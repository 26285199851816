import styled from 'styled-components';

interface IStyledContactForm {
  restrictWidth: any;
  isError: any;
}

export const StyledContactForm = styled.form<IStyledContactForm>`
  display: flex;
  max-width: ${({ restrictWidth }) => restrictWidth};
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
`;
