import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledDropdown } from './styles';

interface IDropdown {
  list: { value: string; label: string }[];
  changeFn?(e: React.ChangeEvent<HTMLSelectElement>): void;
  icon: IconProp;
  placeholder: string;
  restrictWidth?: string;
  className?: string;
  refTo?: any;
  name?: string;
  disabled?: boolean;
}

export const Dropdown = ({
  list,
  changeFn,
  icon,
  placeholder,
  restrictWidth = `100%`,
  className,
  refTo,
  name,
  disabled = false,
}: IDropdown) => {
  const uuidNum = uuidv4();
  return (
    <StyledDropdown
      htmlFor={`${name}_${uuidNum}`}
      aria-label={name}
      restrictWidth={restrictWidth}
      className={className}
    >
      <FontAwesomeIcon className="dropdown__icon" icon={faAngleDown} />
      {icon ? (
        <FontAwesomeIcon className="dropdown__left-icon" icon={icon} />
      ) : null}
      {` `}
      <select
        id={`${name}_${uuidNum}`}
        name={name}
        ref={refTo}
        onBlur={changeFn}
        defaultValue=""
        required
        disabled={disabled}
      >
        <option aria-selected key={0} value="" disabled hidden>
          {placeholder}
        </option>
        {list.map(({ value, label }) => {
          const optionUuidNum = uuidv4();
          return (
            <option aria-selected={false} key={optionUuidNum} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </StyledDropdown>
  );
};
